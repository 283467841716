import { navigate } from 'gatsby';
import Cookies from 'js-cookie';

export const onPreRouteUpdate = ({ location }) => {
  if (typeof navigator === `undefined`) {
    return;
  }

  const cookieLocale = Cookies.get('_lang') || 'en';
  const pathName = location.pathname.match(/^\/(en|it|pt)\/(.*)/);

  const pathLocale = pathName && pathName[1] ? pathName[1] : 'en';

  const redirect = (pathName && location.pathname === pathName[0]) ? '/' : location.pathname;

  const browserLocale =
    (navigator && navigator.language && navigator.language.split('-')[0]) ||
    'en';

  if (pathLocale !== cookieLocale) {
    const redirectPath =
      pathName && pathName[2]
        ? `/${cookieLocale}/${pathName[2]}`
        : `/${cookieLocale}${redirect}`;
    return navigate(redirectPath, { replace: true });
  }

  if (pathLocale !== browserLocale && pathLocale !== cookieLocale) {
    const redirectPath =
      pathName && pathName[2]
        ? `/${browserLocale}/${pathName[2]}`
        : `/${browserLocale}${redirect}`;
    Cookies.set('_lang', browserLocale);
    return navigate(redirectPath, { replace: true });
  }
};
